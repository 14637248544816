const PROD_URL = "https://dev.blockvision.it/api/v2/midori";
const BUCKET_URL = "https://platform-frankfurt.eu-central-1.linodeobjects.com/";

var GRAPHQLURL = "";
if(window.location.host.includes("localhost")){
  GRAPHQLURL = "http://localhost:4000/graphql"; 
}else{
  GRAPHQLURL = "https://dev.blockvision.it/prisma/graphql";
}
const CLIENT = localStorage.getItem("PRISMA_CLIENT");
const JWT_TOKEN = localStorage.getItem("PRISMA_TOKEN");

/************** FILE **************/
export function getFileUploadUrl(file, fileHash) {

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"media": [
				{
					"file_name": fileHash + '.' + (file.type).split('/')[1],
					"mime_type": file.type
				}
			]
		});

		xhr.onload = () => resolve(JSON.parse(xhr.responseText));
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", PROD_URL + "/api/v1/file/url");
		xhr.setRequestHeader("Content-Type", "application/json");

		xhr.send(data);
	});
}

export function uploadFileToBucket(fileAsBinary, fileType, url) {
	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = fileAsBinary;

		xhr.onload = () => resolve(xhr.status);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("PUT", url);

		xhr.setRequestHeader("Content-Type", fileType);
		xhr.send(data);
	});
}

export function uploadFileToDb(fileId, fileType, fileName, fileHash, fileExtension) {
	/* return new Promise((resolve, reject) => {
 
		var data = JSON.stringify({
			"id": fileId,
			"url": BUCKET_URL + fileHash + '.' + (fileType).split('/')[1],
			"kind": fileType,
			"file_name": fileName,
			"deleted": false,
			"hash": fileHash
		});
		  
		const xhr = new XMLHttpRequest();

		xhr.onload = () => resolve(JSON.parse(xhr.responseText));
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", PROD_URL+"/api/v1/file");
		xhr.setRequestHeader("Content-Type", "application/json");

		xhr.send(data);
	});  */

	const mutation = `mutation CreateFile($value: FileInput!) {
        createFile(value: $value) {
          fileName
          hash
          id
          kind
          url
        }
      }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "CreateFile",
			"variables": {
				"value": {
					"idClient": CLIENT,
					"idUser": CLIENT,
					"fileName": fileName,
					"hash": fileHash,
					"kind": fileType,
					"url": BUCKET_URL + fileHash + '.' + (fileExtension).split('/')[1],
				}
			}
		}
		);

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

/************** MEDIA **************/
export function uploadMedia(user, client, mediaId, fileId, fileName, fileDescription, fileShow) {
	/* return new Promise((resolve, reject) => {
 
		var data = JSON.stringify({
			"id": mediaId,
			"idUser": [USER],
			"idClient": [CLIENT],
			"name": fileName,
			"description": fileDescription,
			"show": fileShow,
			"file": fileId,
			"deleted": false
		});
		  
		const xhr = new XMLHttpRequest();

		xhr.onload = () => resolve(JSON.parse(xhr.responseText));
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", PROD_URL+"/api/v1/media");
		xhr.setRequestHeader("Content-Type", "application/json");
	    
		xhr.send(data);
	 });  */

	const mutation = `mutation CreateMedia($value: MediaInput!) {
        createMedia(value: $value) {
          id
          idClient
          idUser
          name
          description
          show
          referredFileId
          referredFile {
            fileName
            url
          }
        }
      }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "CreateMedia",
			"variables": {
				"value": {
					"description": fileDescription,
					"idClient": CLIENT,
					"idUser": CLIENT,
					"name": fileName,
					"show": fileShow,
					"referredFileId": fileId
				}
			}
		}
		);

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

/************** DOCUMENT **************/
export function uploadDocument(user, client, documentId, fileId, fileName, fileDescription, documentStatus, documentType, documentExpirationDate) {
	/* return new Promise((resolve, reject) => {
 
		var data = JSON.stringify({
			"id": documentId,
			"idUser": [USER],
			"idClient": [CLIENT],
			"name": fileName,
			"description": fileDescription,
			"file": fileId,
			"status": "TO_BE_CONFIRMED",
			"expirationDate": 123456789,
			"type": documentType,
			"signer": ["yhgvcsdc67w67qe6gdcsjhcbsdhgcsvdcshdwuy"],
			"txId": "1234556778898764321223456678765323",
			"ledger": ["Iota"],
			"authorKey": "873628937ugbcueyr364ejhbse3874",
			"deleted": false
		});
		  
		const xhr = new XMLHttpRequest();

		xhr.onload = () => resolve(JSON.parse(xhr.responseText));
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", PROD_URL+"/api/v1/document");
		xhr.setRequestHeader("Content-Type", "application/json");
	    
		xhr.send(data);
	 });  */

	const mutation = `mutation CreateDocument($value: DocumentInput!) {
        createDocument(value: $value) {
          description
          expirationDate
          id
          idClient
          idUser
          name
          referredFile {
            fileName
            url
          }
          referredFileId
          show
          status
          type
        }
      }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "CreateDocument",
			"variables": {
				"value": {
					"description": fileDescription,
					"expirationDate": documentExpirationDate,
					"idClient": CLIENT,
					"idUser": CLIENT,
					"name": fileName,
					"referredFileId": fileId,
					"show": true,
					"status": documentStatus,
					"type": documentType
				}
			}
		}
		);

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

/************** CLAIM **************/
export function uploadClaim(user, client, claimId, fileId, fileName, fileDescription, claimType, documentId, fileStatus) {
	/* return new Promise((resolve, reject) => {
 
		var data = JSON.stringify({
			"id": claimId,
			"idUser": [USER],
			"idClient": [CLIENT],
			"name": fileName,
			"description": fileDescription,
			"file": fileId,
			"type": {
				"info": {
					"label": "work"
				},
				"iconWhite": "https://label_white.com",
				"iconBlack": "https://label_black.com"
			},
			"status": "TO_BE_CONFIRMED",
			"document": documentId,
			"deleted": false
		});
		  
		const xhr = new XMLHttpRequest();

		xhr.onload = () => resolve(JSON.parse(xhr.responseText));
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", PROD_URL+"/api/v1/claim");
		xhr.setRequestHeader("Content-Type", "application/json");
	    
		xhr.send(data);
	 });  */

	const mutation = `mutation CreateClaim($value: ClaimInput!) {
        createClaim(value: $value) {
          id
          idClient
          idUser
          name
          description
          show
          referredFileId
          referredFile {
            url
            fileName
          }
          confirmationDocId
          confirmationDoc {
            name
            id
            referredFile {
              url
              fileName
            }
          }
          status
          type {
            iconBlack
            iconWhite
            label
          }
        }
      }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "CreateClaim",
			"variables": {
				"value": {
					"confirmationDocId": documentId,
					"description": fileDescription,
					"idClient": CLIENT,
					"idUser": CLIENT,
					"name": fileName,
					"referredFileId": fileId,
					"show": true,
					"status": fileStatus,
					"type": claimType
				}
			}
		}
		);

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

/************** SUPPLIER **************/
export function uploadSupplier(supplier) {
	const mutation = `mutation CreateSupplier($value: SupplierInput!) {
        createSupplier(value: $value) {
          id
          idClient
          idUser
          name
          displayName
          showDisplayName
          company {
            name
            vatNumber
            size
          }
          description
          address {
            city
            showCity
            province
            showProvince
            address
            showAddress
            placeOnEarth
          }
          phoneContacts {
            type
            value
            order
            isDefault
          }
          mailContacts {
            type
            value
            order
            isDefault
          }
          webContacts {
            type
            value
            order
            isDefault
          }
          productionInfo {
            minOrderQuantity
            minOrderQuantityPerStyle
            numberOfFemaleEmployees
            numberOfMaleEmployees
            numberOfPermanentEmployees
            standardDelayTimeDays
            totProductionQuantity
          }
          media {
            name
            id
          }
          documents {
            name
            id
          }
          claims {
            name
            id
          }
          image {
            name
            id
            referredFile {
              url
              id
            }
          }
          supplierGroup
          main
          auditable {
            state
            createdOn
          }
        }
      }`

	supplier.idClient = CLIENT;
	supplier.idUser = CLIENT

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "CreateSupplier",
			"variables": {
				"value": supplier
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function updateSupplier(supplier, updateSupplierId) {
	const mutation = `mutation UpdateSupplier($updateSupplierId: ID!, $value: SupplierInput!) {
    updateSupplier(id: $updateSupplierId, value: $value) {
        id
        idClient
        idUser
        name
        displayName
        showDisplayName
        company {
          name
          vatNumber
          size
        }
        description
        address {
          city
          showCity
          province
          showProvince
          address
          showAddress
          placeOnEarth
        }
        phoneContacts {
          type
          value
          order
          isDefault
        }
        mailContacts {
          type
          value
          order
          isDefault
        }
        webContacts {
          type
          value
          order
          isDefault
        }
        productionInfo {
          minOrderQuantity
          minOrderQuantityPerStyle
          numberOfFemaleEmployees
          numberOfMaleEmployees
          numberOfPermanentEmployees
          standardDelayTimeDays
          totProductionQuantity
        }
        media {
          name
          id
        }
        documents {
          name
          id
        }
        claims {
          name
          id
        }
        image {
          name
          id
          referredFile {
            url
            id
          }
        }
        supplierGroup
        main
        auditable {
          state
          createdOn
        }
      }
    }`

	supplier.idClient = CLIENT;
	supplier.idUser = CLIENT

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "UpdateSupplier",
			"variables": {
				"updateSupplierId": updateSupplierId,
				"value": supplier
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function deleteSupplier(id) {
	const mutation = `mutation DeleteSupplier($idClient: ID!, $deleteSupplierId: ID!) {
    deleteSupplier(idClient: $idClient, id: $deleteSupplierId)
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "DeleteSupplier",
			"variables": {
				"idClient": CLIENT,
				"deleteSupplierId": id
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function updateMedia(media) {
	const mutation = `mutation UpdateMedia($updateMediaId: ID!, $value: MediaInput!) {
    updateMedia(id: $updateMediaId, value: $value) {
      id
      name
      description
      show
      referredFile {
        id
        fileName
        url
      }
    }
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "UpdateMedia",
			"variables": {
				"updateMediaId": media.id,
				"value": {
					"idClient": CLIENT,
					"idUser": CLIENT,
					"description": media.description,
					"name": media.name,
					"show": media.show
				}
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function deleteSupplierMedia(idMedia, idSupplier) {
	const mutation = `mutation DeleteSupplierMedia($idClient: ID!, $idMedia: ID!, $idSupplier: ID!) {
    deleteSupplierMedia(idClient: $idClient, idMedia: $idMedia, idSupplier: $idSupplier)
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "DeleteSupplierMedia",
			"variables": {
				"idClient": CLIENT,
				"idMedia": idMedia,
				"idSupplier": idSupplier
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function deleteSupplierDocument(idDocument, idSupplier) {
	const mutation = `mutation DeleteSupplierDocument($idClient: ID!, $idDocument: ID!, $idSupplier: ID!) {
    deleteSupplierDocument(idClient: $idClient, idDocument: $idDocument, idSupplier: $idSupplier)
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "DeleteSupplierDocument",
			"variables": {
				"idClient": CLIENT,
				"idDocument": idDocument,
				"idSupplier": idSupplier
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function deleteSupplierClaim(idClaim, idSupplier) {
	const mutation = `mutation DeleteSupplierClaim($idClient: ID!, $idClaim: ID!, $idSupplier: ID!) {
    deleteSupplierClaim(idClient: $idClient, idClaim: $idClaim, idSupplier: $idSupplier)
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "DeleteSupplierClaim",
			"variables": {
				"idClient": CLIENT,
				"idClaim": idClaim,
				"idSupplier": idSupplier
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function updateDocument(document) {
	const mutation = `mutation UpdateDocument($updateDocumentId: ID!, $value: DocumentInput!) {
    updateDocument(id: $updateDocumentId, value: $value) {
      id
      idClient
      idUser
      name
      description
      show
      referredFile {
        id
        fileName
        url
      }
      expirationDate
      type
      status
    }
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "UpdateDocument",
			"variables": {
				"updateDocumentId": document.id,
				"value": {
					"description": document.description,
					"expirationDate": document.expirationDate,
					"idClient": CLIENT,
					"idUser": CLIENT,
					"name": document.name,
					"show": document.show,
					"status": document.status,
					"type": document.type
				}
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function updateClaim(claim) {
	const mutation = `mutation UpdateClaim($value: ClaimInput!, $updateClaimId: ID!) {
    updateClaim(value: $value, id: $updateClaimId) {
      id
      idClient
      idUser
      name
      description
      show
      referredFileId
      referredFile {
        fileName
        id
        url
      }
      confirmationDoc {
        name
        id
        referredFile {
          url
        }
      }
      type {
        label
        iconWhite
        iconBlack
      }
      status
    }
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "UpdateClaim",
			"variables": {
				"updateClaimId": claim.id,
				"value": {
					"confirmationDocId": claim.confirmationDoc.id,
					"description": claim.description,
					"idClient": CLIENT,
					"idUser": CLIENT,
					"name": claim.name,
					"show": claim.show,
					"status": claim.status,
					"type": {
						"iconBlack": claim.type.iconBlack,
						"iconWhite": claim.type.iconWhite,
						"label": claim.type.label
					}
				}
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function getSuppliers(filter) {
	const query = `query Suppliers($filter: SupplierQueryFilter) {
    suppliers(filter: $filter) {
      id
      idClient
      idUser
      name
      displayName
      showDisplayName
      company {
        name
        vatNumber
        size
      }
      description
      address {
        city
        showCity
        province
        showProvince
        address
        showAddress
        placeOnEarth
      }
      phoneContacts {
        type
        value
        order
        isDefault
      }
      mailContacts {
        type
        value
        order
        isDefault
      }
      webContacts {
        type
        value
        order
        isDefault
      }
      productionInfo {
        minOrderQuantity
        minOrderQuantityPerStyle
        numberOfFemaleEmployees
        numberOfMaleEmployees
        numberOfPermanentEmployees
        standardDelayTimeDays
        totProductionQuantity
      }
      media {
        name
        id
        referredFile {
          id
          url
          fileName
        }
        show
        description
      }
      documents {
        name
        id
        expirationDate
        description
        referredFile {
          url
          id
          fileName
        }
        show
        status
        type
      }
      claims {
        name
        id
        referredFile {
          id
          fileName
          url
        }
        show
        status
        type {
          label
          iconWhite
          iconBlack
        }
        confirmationDoc {
          id
          name
          referredFile {
            fileName
            url
            id
          }
        }
        description
      }
      supplierGroup
      main
      auditable {
        state
        createdOn
      }
      imageId
      image {
        name
        referredFile {
          id
          url
        }
      }
    }
  }`

	var queryFilter = {
		"idClient": CLIENT
	}

	if (filter && filter.id) {
		queryFilter.id = filter.id
	}

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": query,
			"operationName": "Suppliers",
			"variables": {
				"filter": queryFilter
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

/************** LOGIN **************/
export function login(username, password) {
	const mutation = `mutation Login($value: ClientInput!) {
    login(value: $value)
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "Login",
			"variables": {
				"value": {
					"passWord": password,
					"userName": username
				}
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");

		xhr.send(data);
	});
}

export function submitEmailRequest(email) {
  const mutation = `mutation SubmitEmailRequest($value: String!) {
    submitEmailRequest(value: $value)
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "SubmitEmailRequest",
			"variables": {
				"value": email
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");

		xhr.send(data);
	});
}

export function getClientById(id){
  const mutation = `query Clients($filter: ClientQueryFilter) {
    clients(filter: $filter) {
      userName
    }
  }`

  return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "Clients",
			"variables": {
				"filter": {
          id: id
        }
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");

		xhr.send(data);
	});
}

/************** PRODUCT **************/
export function uploadProduct(product) {
	const mutation = `mutation CreateProduct($value: ProductInput!) {
    createProduct(value: $value) {
      id
      idClient
      idUser
      name
      description
      technicalDescription
      collection {
        name
      }
      garments {
        name
        percentage
      }
      countries
      sizes
      careAndMantainance {
        iconBlack
        iconWhite
        label
      }
      colors
      price
      supplyChainSteps {
        description
        name
        originSupplier {
          id
          name
          description
          image {
            referredFile {
              url
            }
          }
          claims {
            id
            name
            status
            type {
              label
              iconWhite
              iconBlack
            }
            show
            referredFile {
              url
              id
            }
            description
            confirmationDoc {
              id
              name
              referredFile {
                url
                id
              }
            }
          }
          media {
            id
            referredFile {
              id
              url
            }
            name
            description
            show
          }
          documents {
            id
            referredFile {
              id
              url
            }
            name
            description
            expirationDate
            show
            status
            type
          }
        }
        processorSupplier {
          id
          name
          description
          image {
            referredFile {
              url
            }
          }
          claims {
            id
            name
            status
            type {
              label
              iconWhite
              iconBlack
            }
            show
            referredFile {
              url
              id
            }
            description
            confirmationDoc {
              id
              name
              referredFile {
                url
                id
              }
            }
          }
          media {
            id
            referredFile {
              id
              url
            }
            name
            description
            show
          }
          documents {
            id
            referredFile {
              id
              url
            }
            name
            description
            expirationDate
            show
            status
            type
          }
        }
        traderSupplier {
          id
          name
          description
          image {
            referredFile {
              url
            }
          }
          claims {
            id
            name
            status
            type {
              label
              iconWhite
              iconBlack
            }
            show
            referredFile {
              url
              id
            }
            description
            confirmationDoc {
              id
              name
              referredFile {
                url
                id
              }
            }
          }
          media {
            id
            referredFile {
              id
              url
            }
            name
            description
            show
          }
          documents {
            id
            referredFile {
              id
              url
            }
            name
            description
            expirationDate
            show
            status
            type
          }
        }
        owner {
          id
          name
          description
          image {
            referredFile {
              url
            }
          }
          claims {
            id
            name
            status
            type {
              label
              iconWhite
              iconBlack
            }
            show
            referredFile {
              url
              id
            }
            description
            confirmationDoc {
              id
              name
              referredFile {
                url
                id
              }
            }
          }
          media {
            id
            referredFile {
              id
              url
            }
            name
            description
            show
          }
          documents {
            id
            referredFile {
              id
              url
            }
            name
            description
            expirationDate
            show
            status
            type
          }
        }
        type {
          label
          iconWhite
          iconBlack
        }
        id
      }
      totProduction
      supplyChainLinks {
        id
        idClient
        idUser
        distance
        fromHandler
        fromSupplierId
        fromStepTypeID
        fromSupplier{
          id
          idClient
          idUser
          name
          displayName
          showDisplayName
          company {
            name
            vatNumber
            size
          }
          description
          address {
            city
            showCity
            province
            showProvince
            address
            showAddress
            placeOnEarth
          }
          phoneContacts {
            type
            value
            order
            isDefault
          }
          mailContacts {
            type
            value
            order
            isDefault
          }
          webContacts {
            type
            value
            order
            isDefault
          }
          productionInfo {
            minOrderQuantity
            minOrderQuantityPerStyle
            numberOfFemaleEmployees
            numberOfMaleEmployees
            numberOfPermanentEmployees
            standardDelayTimeDays
            totProductionQuantity
          }
          media {
            name
            id
            referredFile {
              id
              url
              fileName
            }
            show
            description
          }
          documents {
            name
            id
            expirationDate
            description
            referredFile {
              url
              id
              fileName
            }
            show
            status
            type
          }
          claims {
            name
            id
            referredFile {
              id
              fileName
              url
            }
            show
            status
            type {
              label
              iconWhite
              iconBlack
            }
            confirmationDoc {
              id
              name
              referredFile {
                fileName
                url
                id
              }
            }
            description
          }
          supplierGroup
          main
          auditable {
            state
            createdOn
          }
          imageId
          image {
            name
            referredFile {
              id
              url
            }
          }
        }
        toHandler
        fromTypeCode
        toSupplierId
        toStepTypeID
        toSupplier{
          id
          idClient
          idUser
          name
          displayName
          showDisplayName
          company {
            name
            vatNumber
            size
          }
          description
          address {
            city
            showCity
            province
            showProvince
            address
            showAddress
            placeOnEarth
          }
          phoneContacts {
            type
            value
            order
            isDefault
          }
          mailContacts {
            type
            value
            order
            isDefault
          }
          webContacts {
            type
            value
            order
            isDefault
          }
          productionInfo {
            minOrderQuantity
            minOrderQuantityPerStyle
            numberOfFemaleEmployees
            numberOfMaleEmployees
            numberOfPermanentEmployees
            standardDelayTimeDays
            totProductionQuantity
          }
          media {
            name
            id
            referredFile {
              id
              url
              fileName
            }
            show
            description
          }
          documents {
            name
            id
            expirationDate
            description
            referredFile {
              url
              id
              fileName
            }
            show
            status
            type
          }
          claims {
            name
            id
            referredFile {
              id
              fileName
              url
            }
            show
            status
            type {
              label
              iconWhite
              iconBlack
            }
            confirmationDoc {
              id
              name
              referredFile {
                fileName
                url
                id
              }
            }
            description
          }
          supplierGroup
          main
          auditable {
            state
            createdOn
          }
          imageId
          image {
            name
            referredFile {
              id
              url
            }
          }
        }
        toTypeCode
        vehicle {
          label
          iconWhite
          iconBlack
        }
        fromSupplierId
        toSupplierId
      }
      media {
        id
        referredFile {
          url
        }
        name
        show
        description
      }
    }
  }`

	product.idClient = CLIENT;
	product.idUser = CLIENT

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "CreateProduct",
			"variables": {
				"value": product
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function updateProduct(product, updateProductId) {
	const mutation = `mutation UpdateProduct($updateProductId: ID!, $value: ProductInput!) {
    updateProduct(id: $updateProductId, value: $value) {
      id
      idClient
      idUser
      name
      description
      technicalDescription
      collection {
        name
      }
      garments {
        name
        percentage
      }
      countries
      sizes
      careAndMantainance {
        iconBlack
        iconWhite
        label
      }
      colors
      price
      supplyChainSteps {
        description
        name
        originSupplier {
          id
          name
          description
          image {
            referredFile {
              url
            }
          }
          claims {
            id
            name
            status
            type {
              label
              iconWhite
              iconBlack
            }
            show
            referredFile {
              url
              id
            }
            description
            confirmationDoc {
              id
              name
              referredFile {
                url
                id
              }
            }
          }
          media {
            id
            referredFile {
              id
              url
            }
            name
            description
            show
          }
          documents {
            id
            referredFile {
              id
              url
            }
            name
            description
            expirationDate
            show
            status
            type
          }
        }
        processorSupplier {
          id
          name
          description
          image {
            referredFile {
              url
            }
          }
          claims {
            id
            name
            status
            type {
              label
              iconWhite
              iconBlack
            }
            show
            referredFile {
              url
              id
            }
            description
            confirmationDoc {
              id
              name
              referredFile {
                url
                id
              }
            }
          }
          media {
            id
            referredFile {
              id
              url
            }
            name
            description
            show
          }
          documents {
            id
            referredFile {
              id
              url
            }
            name
            description
            expirationDate
            show
            status
            type
          }
        }
        traderSupplier {
          id
          name
          description
          image {
            referredFile {
              url
            }
          }
          claims {
            id
            name
            status
            type {
              label
              iconWhite
              iconBlack
            }
            show
            referredFile {
              url
              id
            }
            description
            confirmationDoc {
              id
              name
              referredFile {
                url
                id
              }
            }
          }
          media {
            id
            referredFile {
              id
              url
            }
            name
            description
            show
          }
          documents {
            id
            referredFile {
              id
              url
            }
            name
            description
            expirationDate
            show
            status
            type
          }
        }
        owner {
          id
          name
          description
          image {
            referredFile {
              url
            }
          }
          claims {
            id
            name
            status
            type {
              label
              iconWhite
              iconBlack
            }
            show
            referredFile {
              url
              id
            }
            description
            confirmationDoc {
              id
              name
              referredFile {
                url
                id
              }
            }
          }
          media {
            id
            referredFile {
              id
              url
            }
            name
            description
            show
          }
          documents {
            id
            referredFile {
              id
              url
            }
            name
            description
            expirationDate
            show
            status
            type
          }
        }
        type {
          label
          iconWhite
          iconBlack
        }
        id
      }
      totProduction
      supplyChainLinks {
        id
        idClient
        idUser
        distance
        fromHandler
        fromSupplierId
        fromStepTypeID
        fromSupplier{
          id
          idClient
          idUser
          name
          displayName
          showDisplayName
          company {
            name
            vatNumber
            size
          }
          description
          address {
            city
            showCity
            province
            showProvince
            address
            showAddress
            placeOnEarth
          }
          phoneContacts {
            type
            value
            order
            isDefault
          }
          mailContacts {
            type
            value
            order
            isDefault
          }
          webContacts {
            type
            value
            order
            isDefault
          }
          productionInfo {
            minOrderQuantity
            minOrderQuantityPerStyle
            numberOfFemaleEmployees
            numberOfMaleEmployees
            numberOfPermanentEmployees
            standardDelayTimeDays
            totProductionQuantity
          }
          media {
            name
            id
            referredFile {
              id
              url
              fileName
            }
            show
            description
          }
          documents {
            name
            id
            expirationDate
            description
            referredFile {
              url
              id
              fileName
            }
            show
            status
            type
          }
          claims {
            name
            id
            referredFile {
              id
              fileName
              url
            }
            show
            status
            type {
              label
              iconWhite
              iconBlack
            }
            confirmationDoc {
              id
              name
              referredFile {
                fileName
                url
                id
              }
            }
            description
          }
          supplierGroup
          main
          auditable {
            state
            createdOn
          }
          imageId
          image {
            name
            referredFile {
              id
              url
            }
          }
        }
        toHandler
        fromTypeCode
        toSupplierId
        toStepTypeID
        toSupplier{
          id
          idClient
          idUser
          name
          displayName
          showDisplayName
          company {
            name
            vatNumber
            size
          }
          description
          address {
            city
            showCity
            province
            showProvince
            address
            showAddress
            placeOnEarth
          }
          phoneContacts {
            type
            value
            order
            isDefault
          }
          mailContacts {
            type
            value
            order
            isDefault
          }
          webContacts {
            type
            value
            order
            isDefault
          }
          productionInfo {
            minOrderQuantity
            minOrderQuantityPerStyle
            numberOfFemaleEmployees
            numberOfMaleEmployees
            numberOfPermanentEmployees
            standardDelayTimeDays
            totProductionQuantity
          }
          media {
            name
            id
            referredFile {
              id
              url
              fileName
            }
            show
            description
          }
          documents {
            name
            id
            expirationDate
            description
            referredFile {
              url
              id
              fileName
            }
            show
            status
            type
          }
          claims {
            name
            id
            referredFile {
              id
              fileName
              url
            }
            show
            status
            type {
              label
              iconWhite
              iconBlack
            }
            confirmationDoc {
              id
              name
              referredFile {
                fileName
                url
                id
              }
            }
            description
          }
          supplierGroup
          main
          auditable {
            state
            createdOn
          }
          imageId
          image {
            name
            referredFile {
              id
              url
            }
          }
        }
        toTypeCode
        vehicle {
          label
          iconWhite
          iconBlack
        }
        fromSupplierId
        toSupplierId
      }
      media {
        id
        referredFile {
          url
        }
        name
        show
        description
      }
    }
  }`

	product.idClient = CLIENT;
	product.idUser = CLIENT

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "UpdateProduct",
			"variables": {
				"updateProductId": updateProductId,
				"value": product
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function deleteProductMedia(idMedia, idProduct) {
	const mutation = `mutation DeleteProductMedia($idClient: ID!, $idMedia: ID!, $idProduct: ID!) {
    deleteProductMedia(idClient: $idClient, idMedia: $idMedia, idProduct: $idProduct)
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "DeleteProductMedia",
			"variables": {
				"idClient": CLIENT,
				"idMedia": idMedia,
				"idProduct": idProduct
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function uploadCollection(collectionName) {
	const mutation = `mutation CreateCollection($value: CollectionInput!) {
    createCollection(value: $value) {
      id
      idClient
      idUser
      name
    }
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "CreateCollection",
			"variables": {
				"value": {
					"name": collectionName,
					"idUser": CLIENT,
					"idClient": CLIENT
				}
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function getCollections() {
	const query = `query Collections($filter: CollectionQueryFilter) {
    collections(filter: $filter) {
      id
      idClient
      idUser
      name
    }
  }`

	var queryFilter = {
		"idClient": CLIENT
	}

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": query,
			"operationName": "Collections",
			"variables": {
				"filter": queryFilter
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function getProducts(filter) {
	const query = `query Products($filter: ProductQueryFilter) {
    products(filter: $filter) {
      id
      idClient
      idUser
      name
      description
      technicalDescription
      collection {
        name
      }
      garments {
        name
        percentage
      }
      countries
      sizes
      careAndMantainance {
        iconBlack
        iconWhite
        label
      }
      colors
      price
      supplyChainSteps {
        description
        name
        originSupplier {
          id
          name
          description
          image {
            referredFile {
              url
            }
          }
          claims {
            id
            name
            status
            type {
              label
              iconWhite
              iconBlack
            }
            show
            referredFile {
              url
              id
            }
            description
            confirmationDoc {
              id
              name
              referredFile {
                url
                id
              }
            }
          }
          media {
            id
            referredFile {
              id
              url
            }
            name
            description
            show
          }
          documents {
            id
            referredFile {
              id
              url
            }
            name
            description
            expirationDate
            show
            status
            type
          }
          address {
            city
            showCity
            province
            showProvince
            address
            showAddress
            placeOnEarth
          }
          company {
            name
            size
          }
        }
        processorSupplier {
          id
          name
          description
          image {
            referredFile {
              url
            }
          }
          claims {
            id
            name
            status
            type {
              label
              iconWhite
              iconBlack
            }
            show
            referredFile {
              url
              id
            }
            description
            confirmationDoc {
              id
              name
              referredFile {
                url
                id
              }
            }
          }
          media {
            id
            referredFile {
              id
              url
            }
            name
            description
            show
          }
          documents {
            id
            referredFile {
              id
              url
            }
            name
            description
            expirationDate
            show
            status
            type
          }
          address {
            city
            showCity
            province
            showProvince
            address
            showAddress
            placeOnEarth
          }
          company {
            name
            size
          }
        }
        traderSupplier {
          id
          name
          description
          image {
            referredFile {
              url
            }
          }
          claims {
            id
            name
            status
            type {
              label
              iconWhite
              iconBlack
            }
            show
            referredFile {
              url
              id
            }
            description
            confirmationDoc {
              id
              name
              referredFile {
                url
                id
              }
            }
          }
          media {
            id
            referredFile {
              id
              url
            }
            name
            description
            show
          }
          documents {
            id
            referredFile {
              id
              url
            }
            name
            description
            expirationDate
            show
            status
            type
          }
          address {
            city
            showCity
            province
            showProvince
            address
            showAddress
            placeOnEarth
          }
          company {
            name
            size
          }
        }
        owner {
          id
          name
          description
          image {
            referredFile {
              url
            }
          }
          claims {
            id
            name
            status
            type {
              label
              iconWhite
              iconBlack
            }
            show
            referredFile {
              url
              id
            }
            description
            confirmationDoc {
              id
              name
              referredFile {
                url
                id
              }
            }
          }
          media {
            id
            referredFile {
              id
              url
            }
            name
            description
            show
          }
          documents {
            id
            referredFile {
              id
              url
            }
            name
            description
            expirationDate
            show
            status
            type
          }
          address {
            city
            showCity
            province
            showProvince
            address
            showAddress
            placeOnEarth
          }
          company {
            name
            size
          }
        }
        id
        type {
          label
          iconWhite
          iconBlack
          code
        }
      }
      totProduction
      supplyChainLinks {
        id
        idClient
        idUser
        distance
        fromHandler
        fromID
        fromStepTypeID
        fromSupplier{
          id
          idClient
          idUser
          name
          displayName
          showDisplayName
          company {
            name
            vatNumber
            size
          }
          description
          address {
            city
            showCity
            province
            showProvince
            address
            showAddress
            placeOnEarth
          }
          phoneContacts {
            type
            value
            order
            isDefault
          }
          mailContacts {
            type
            value
            order
            isDefault
          }
          webContacts {
            type
            value
            order
            isDefault
          }
          productionInfo {
            minOrderQuantity
            minOrderQuantityPerStyle
            numberOfFemaleEmployees
            numberOfMaleEmployees
            numberOfPermanentEmployees
            standardDelayTimeDays
            totProductionQuantity
          }
          media {
            name
            id
            referredFile {
              id
              url
              fileName
            }
            show
            description
          }
          documents {
            name
            id
            expirationDate
            description
            referredFile {
              url
              id
              fileName
            }
            show
            status
            type
          }
          claims {
            name
            id
            referredFile {
              id
              fileName
              url
            }
            show
            status
            type {
              label
              iconWhite
              iconBlack
            }
            confirmationDoc {
              id
              name
              referredFile {
                fileName
                url
                id
              }
            }
            description
          }
          supplierGroup
          main
          auditable {
            state
            createdOn
          }
          imageId
          image {
            name
            referredFile {
              id
              url
            }
          }
        }
        toHandler
        fromTypeCode
        toID
        toStepTypeID
        toSupplier{
          id
          idClient
          idUser
          name
          displayName
          showDisplayName
          company {
            name
            vatNumber
            size
          }
          description
          address {
            city
            showCity
            province
            showProvince
            address
            showAddress
            placeOnEarth
          }
          phoneContacts {
            type
            value
            order
            isDefault
          }
          mailContacts {
            type
            value
            order
            isDefault
          }
          webContacts {
            type
            value
            order
            isDefault
          }
          productionInfo {
            minOrderQuantity
            minOrderQuantityPerStyle
            numberOfFemaleEmployees
            numberOfMaleEmployees
            numberOfPermanentEmployees
            standardDelayTimeDays
            totProductionQuantity
          }
          media {
            name
            id
            referredFile {
              id
              url
              fileName
            }
            show
            description
          }
          documents {
            name
            id
            expirationDate
            description
            referredFile {
              url
              id
              fileName
            }
            show
            status
            type
          }
          claims {
            name
            id
            referredFile {
              id
              fileName
              url
            }
            show
            status
            type {
              label
              iconWhite
              iconBlack
            }
            confirmationDoc {
              id
              name
              referredFile {
                fileName
                url
                id
              }
            }
            description
          }
          supplierGroup
          main
          auditable {
            state
            createdOn
          }
          imageId
          image {
            name
            referredFile {
              id
              url
            }
          }
        }
        toTypeCode
        vehicle {
          label
          iconWhite
          iconBlack
        }
        fromSupplierId
        toSupplierId
      }
      media {
        id
        referredFile {
          url
        }
        name
        show
        description
      }
    }
  }`

	var queryFilter = {
		"idClient": CLIENT
	}

	if (filter && filter.id) {
		queryFilter.id = filter.id
	}

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": query,
			"operationName": "Products",
			"variables": {
				"filter": queryFilter
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function deleteProduct(id) {
	const mutation = `mutation DeleteProduct($idClient: ID!, $deleteProductId: ID!) {
    deleteProduct(idClient: $idClient, id: $deleteProductId)
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "DeleteProduct",
			"variables": {
				"idClient": CLIENT,
				"deleteProductId": id
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function getFreeNfcTags(limit, prismaId) {
	const query = `query NfcTags($filter: NfcTagQueryFilter, $limit: Int) {
    nfcTags(filter: $filter, limit: $limit) {
      id
      internalId
      prismaId
      assigned
    }
  }`

  const filter = {
    "idClient": CLIENT,
    "assigned": false,
  }

  if(prismaId !== ""){
    filter.prismaId = prismaId
  }
	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": query,
			"operationName": "NfcTags",
			"variables": {
				"filter": filter,
				"limit": parseInt(limit)
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function getNfcTags(filter) {
	const query = `query NfcTags($filter: NfcTagQueryFilter) {
    nfcTags(filter: $filter) {
      id
      internalId
      prismaId
      assigned
    }
  }`

	if(CLIENT){
    filter.idClient = CLIENT;
  }

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": query,
			"operationName": "NfcTags",
			"variables": {
				"filter": filter
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");

		xhr.send(data);
	});
}


/************** SUPPLY CHAIN STEP **************/
export function uploadSupplyChainStep(step) {
	const mutation = `mutation CreateSupplyChainStep($value: SupplyChainStepInput!) {
    createSupplyChainStep(value: $value) {
      id
      idClient
      idUser
      name
      description
      type {
        label
        iconWhite
        iconBlack
        code
      }
    }
  }`

	step.idClient = CLIENT;
	step.idUser = CLIENT;

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "CreateSupplyChainStep",
			"variables": {
				"value": step
			}
		}
		);

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function updateSupplyChainStep(step, id) {
	const mutation = `mutation UpdateSupplyChainStep($updateSupplyChainStepId: ID!, $value: SupplyChainStepInput!) {
    updateSupplyChainStep(id: $updateSupplyChainStepId, value: $value) {
      id
      idClient
      idUser
      name
      description
      type {
        label
        iconWhite
        iconBlack
        code
      }
    }
  }`

	step.idClient = CLIENT;
	step.idUser = CLIENT;

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "UpdateSupplyChainStep",
			"variables": {
				"updateSupplyChainStepId": id,
				"value": step
			}
		}
		);

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function deleteProductSupplyChainStep(idSupplyChainStep, idProduct) {
	const mutation = `mutation DeleteProductSupplyChainStep($idClient: ID!, $idSupplyChainStep: ID!, $idProduct: ID!) {
    deleteProductSupplyChainStep(idClient: $idClient, idSupplyChainStep: $idSupplyChainStep, idProduct: $idProduct)
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "DeleteProductSupplyChainStep",
			"variables": {
				"idClient": CLIENT,
				"idSupplyChainStep": idSupplyChainStep,
				"idProduct": idProduct
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

/************** SUPPLY CHAIN LINK **************/

export function uploadSupplyChainLink(link) {
	const mutation = `mutation CreateSupplyChainLink($value: SupplyChainLinkInput!) {
    createSupplyChainLink(value: $value) {
      id
      idClient
      idUser
      distance
      fromHandler
      fromSupplierId
      fromStepTypeID
      fromSupplier{
        id
        idClient
        idUser
        name
        displayName
        showDisplayName
        company {
          name
          vatNumber
          size
        }
        description
        address {
          city
          showCity
          province
          showProvince
          address
          showAddress
          placeOnEarth
        }
        phoneContacts {
          type
          value
          order
          isDefault
        }
        mailContacts {
          type
          value
          order
          isDefault
        }
        webContacts {
          type
          value
          order
          isDefault
        }
        productionInfo {
          minOrderQuantity
          minOrderQuantityPerStyle
          numberOfFemaleEmployees
          numberOfMaleEmployees
          numberOfPermanentEmployees
          standardDelayTimeDays
          totProductionQuantity
        }
        media {
          name
          id
          referredFile {
            id
            url
            fileName
          }
          show
          description
        }
        documents {
          name
          id
          expirationDate
          description
          referredFile {
            url
            id
            fileName
          }
          show
          status
          type
        }
        claims {
          name
          id
          referredFile {
            id
            fileName
            url
          }
          show
          status
          type {
            label
            iconWhite
            iconBlack
          }
          confirmationDoc {
            id
            name
            referredFile {
              fileName
              url
              id
            }
          }
          description
        }
        supplierGroup
        main
        auditable {
          state
          createdOn
        }
        imageId
        image {
          name
          referredFile {
            id
            url
          }
        }
      }
      toHandler
      fromTypeCode
      toHandler
      toSupplierId
      toStepTypeID
      toSupplier{
        id
        idClient
        idUser
        name
        displayName
        showDisplayName
        company {
          name
          vatNumber
          size
        }
        description
        address {
          city
          showCity
          province
          showProvince
          address
          showAddress
          placeOnEarth
        }
        phoneContacts {
          type
          value
          order
          isDefault
        }
        mailContacts {
          type
          value
          order
          isDefault
        }
        webContacts {
          type
          value
          order
          isDefault
        }
        productionInfo {
          minOrderQuantity
          minOrderQuantityPerStyle
          numberOfFemaleEmployees
          numberOfMaleEmployees
          numberOfPermanentEmployees
          standardDelayTimeDays
          totProductionQuantity
        }
        media {
          name
          id
          referredFile {
            id
            url
            fileName
          }
          show
          description
        }
        documents {
          name
          id
          expirationDate
          description
          referredFile {
            url
            id
            fileName
          }
          show
          status
          type
        }
        claims {
          name
          id
          referredFile {
            id
            fileName
            url
          }
          show
          status
          type {
            label
            iconWhite
            iconBlack
          }
          confirmationDoc {
            id
            name
            referredFile {
              fileName
              url
              id
            }
          }
          description
        }
        supplierGroup
        main
        auditable {
          state
          createdOn
        }
        imageId
        image {
          name
          referredFile {
            id
            url
          }
        }
      }
      toTypeCode
      vehicle {
        label
        iconWhite
        iconBlack
      }
      fromSupplierId
      toSupplierId
    }
  }`

	link.idClient = CLIENT;
	link.idUser = CLIENT;

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "CreateSupplyChainLink",
			"variables": {
				"value": link
			}
		}
		);

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function updateSupplyChainLink(link, id) {
	const mutation = `mutation UpdateSupplyChainLink($updateSupplyChainLinkId: ID!, $value: SupplyChainStepInput!) {
    updateSupplyChainLink(id: $updateSupplyChainLinkId, value: $value) {
      id
      idClient
      idUser
      distance
      fromHandler
      fromSupplierId
      fromStepTypeID
      toTypeCode
      vehicle {
        label
        iconWhite
        iconBlack
      }
      fromSupplierId
      toSupplierId
    }
  }`

	link.idClient = CLIENT;
	link.idUser = CLIENT;

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "UpdateSupplyChainLink",
			"variables": {
				"updateSupplyChainLinkId": id,
				"value": step
			}
		}
		);

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function deleteProductSupplyChainLink(idSupplyChainLink, idProduct) {
	const mutation = `mutation DeleteProductSupplyChainLink($idClient: ID!, $idSupplyChainLink: ID!, $idProduct: ID!) {
    deleteProductSupplyChainLink(idClient: $idClient, idSupplyChainLink: $idSupplyChainLink, idProduct: $idProduct)
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "DeleteProductSupplyChainLink",
			"variables": {
				"idClient": CLIENT,
				"idSupplyChainLink": idSupplyChainLink,
				"idProduct": idProduct
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

/* PRODUCT UNITY */
export function createProductUnity(unity) {
	const mutation = `mutation CreateProductUnity($value: ProductUnityInput!) {
    createProductUnity(value: $value) {
      id
      idUser
      productId
      nfcTagId
      product {
        name
      }
    }
  }`

	unity.idClient = CLIENT;
	unity.idUser = CLIENT;

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "CreateProductUnity",
			"variables": {
				"value": unity
			}
		}
		);

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

export function getProductUnities(filter) {
	const query = `query ProducUnities($filter: ProductUnityQueryFilter) {
    productUnities(filter: $filter) {
      id
      nfcTag{
        prismaId
        internalId
      }
      nfcTagId
      productId
      product {
        id
        idClient
        idUser
        name
        description
        technicalDescription
        collection {
          name
        }
        garments {
          name
          percentage
        }
        countries
        sizes
        careAndMantainance {
          iconBlack
          iconWhite
          label
        }
        colors
        price
        supplyChainSteps {
          description
          name
          originSupplier {
            id
            name
            description
            image {
              referredFile {
                url
              }
            }
            claims {
              id
              name
              status
              type {
                label
                iconWhite
                iconBlack
              }
              show
              referredFile {
                url
                id
              }
              description
              confirmationDoc {
                id
                name
                referredFile {
                  url
                  id
                }
              }
            }
            media {
              id
              referredFile {
                id
                url
              }
              name
              description
              show
            }
            documents {
              id
              referredFile {
                id
                url
              }
              name
              description
              expirationDate
              show
              status
              type
            }
            address {
              city
              showCity
              province
              showProvince
              address
              showAddress
              placeOnEarth
            }
            company {
              name
              size
            }
            productionInfo {
              minOrderQuantity
              minOrderQuantityPerStyle
              numberOfFemaleEmployees
              numberOfMaleEmployees
              numberOfPermanentEmployees
              standardDelayTimeDays
              totProductionQuantity
            }
          }
          processorSupplier {
            id
            name
            description
            image {
              referredFile {
                url
              }
            }
            claims {
              id
              name
              status
              type {
                label
                iconWhite
                iconBlack
              }
              show
              referredFile {
                url
                id
              }
              description
              confirmationDoc {
                id
                name
                referredFile {
                  url
                  id
                }
              }
            }
            media {
              id
              referredFile {
                id
                url
              }
              name
              description
              show
            }
            documents {
              id
              referredFile {
                id
                url
              }
              name
              description
              expirationDate
              show
              status
              type
            }
            address {
              city
              showCity
              province
              showProvince
              address
              showAddress
              placeOnEarth
            }
            company {
              name
              size
            }
            productionInfo {
              minOrderQuantity
              minOrderQuantityPerStyle
              numberOfFemaleEmployees
              numberOfMaleEmployees
              numberOfPermanentEmployees
              standardDelayTimeDays
              totProductionQuantity
            }
          }
          traderSupplier {
            id
            name
            description
            image {
              referredFile {
                url
              }
            }
            claims {
              id
              name
              status
              type {
                label
                iconWhite
                iconBlack
              }
              show
              referredFile {
                url
                id
              }
              description
              confirmationDoc {
                id
                name
                referredFile {
                  url
                  id
                }
              }
            }
            media {
              id
              referredFile {
                id
                url
              }
              name
              description
              show
            }
            documents {
              id
              referredFile {
                id
                url
              }
              name
              description
              expirationDate
              show
              status
              type
            }
            address {
              city
              showCity
              province
              showProvince
              address
              showAddress
              placeOnEarth
            }
            company {
              name
              size
            }
            productionInfo {
              minOrderQuantity
              minOrderQuantityPerStyle
              numberOfFemaleEmployees
              numberOfMaleEmployees
              numberOfPermanentEmployees
              standardDelayTimeDays
              totProductionQuantity
            }
          }
          owner {
            id
            name
            description
            image {
              referredFile {
                url
              }
            }
            claims {
              id
              name
              status
              type {
                label
                iconWhite
                iconBlack
              }
              show
              referredFile {
                url
                id
              }
              description
              confirmationDoc {
                id
                name
                referredFile {
                  url
                  id
                }
              }
            }
            media {
              id
              referredFile {
                id
                url
              }
              name
              description
              show
            }
            documents {
              id
              referredFile {
                id
                url
              }
              name
              description
              expirationDate
              show
              status
              type
            }
            address {
              city
              showCity
              province
              showProvince
              address
              showAddress
              placeOnEarth
            }
            company {
              name
              size
            }
            productionInfo {
              minOrderQuantity
              minOrderQuantityPerStyle
              numberOfFemaleEmployees
              numberOfMaleEmployees
              numberOfPermanentEmployees
              standardDelayTimeDays
              totProductionQuantity
            }
          }
          id
          type {
            label
            iconWhite
            iconBlack
            code
          }
        }
        totProduction
        supplyChainLinks {
          id
          idClient
          idUser
          distance
          fromHandler
          fromID
          fromStepTypeID
          fromSupplier{
            id
            idClient
            idUser
            name
            displayName
            showDisplayName
            company {
              name
              vatNumber
              size
            }
            description
            address {
              city
              showCity
              province
              showProvince
              address
              showAddress
              placeOnEarth
            }
            phoneContacts {
              type
              value
              order
              isDefault
            }
            mailContacts {
              type
              value
              order
              isDefault
            }
            webContacts {
              type
              value
              order
              isDefault
            }
            productionInfo {
              minOrderQuantity
              minOrderQuantityPerStyle
              numberOfFemaleEmployees
              numberOfMaleEmployees
              numberOfPermanentEmployees
              standardDelayTimeDays
              totProductionQuantity
            }
            media {
              name
              id
              referredFile {
                id
                url
                fileName
              }
              show
              description
            }
            documents {
              name
              id
              expirationDate
              description
              referredFile {
                url
                id
                fileName
              }
              show
              status
              type
            }
            claims {
              name
              id
              referredFile {
                id
                fileName
                url
              }
              show
              status
              type {
                label
                iconWhite
                iconBlack
              }
              confirmationDoc {
                id
                name
                referredFile {
                  fileName
                  url
                  id
                }
              }
              description
            }
            supplierGroup
            main
            auditable {
              state
              createdOn
            }
            imageId
            image {
              name
              referredFile {
                id
                url
              }
            }
          }
          toHandler
          fromTypeCode
          toID
          toStepTypeID
          toSupplier{
            id
            idClient
            idUser
            name
            displayName
            showDisplayName
            company {
              name
              vatNumber
              size
            }
            description
            address {
              city
              showCity
              province
              showProvince
              address
              showAddress
              placeOnEarth
            }
            phoneContacts {
              type
              value
              order
              isDefault
            }
            mailContacts {
              type
              value
              order
              isDefault
            }
            webContacts {
              type
              value
              order
              isDefault
            }
            productionInfo {
              minOrderQuantity
              minOrderQuantityPerStyle
              numberOfFemaleEmployees
              numberOfMaleEmployees
              numberOfPermanentEmployees
              standardDelayTimeDays
              totProductionQuantity
            }
            media {
              name
              id
              referredFile {
                id
                url
                fileName
              }
              show
              description
            }
            documents {
              name
              id
              expirationDate
              description
              referredFile {
                url
                id
                fileName
              }
              show
              status
              type
            }
            claims {
              name
              id
              referredFile {
                id
                fileName
                url
              }
              show
              status
              type {
                label
                iconWhite
                iconBlack
              }
              confirmationDoc {
                id
                name
                referredFile {
                  fileName
                  url
                  id
                }
              }
              description
            }
            supplierGroup
            main
            auditable {
              state
              createdOn
            }
            imageId
            image {
              name
              referredFile {
                id
                url
              }
            }
          }
          toTypeCode
          vehicle {
            label
            iconWhite
            iconBlack
          }
          fromSupplierId
          toSupplierId
        }
        media {
          id
          referredFile {
            url
          }
          name
          show
          description
        }
      }
    }
  }`

	var queryFilter = { ...filter };
	if(CLIENT){
    queryFilter.idClient = CLIENT;
  }

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": query,
			"operationName": "ProducUnities",
			"variables": {
				"filter": queryFilter
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");

		xhr.send(data);
	});
}

export function createProductUnityBulk(unities) {
	const mutation = `mutation CreateProductUnityBulk($value: ProductUnityBulkInput!) {
    createProductUnityBulk(value: $value) {
      id
      name
      nfcTag {
        internalId
        prismaId
        id
      }
      product {
        name
      }
    }
  }`

	unities.idClient = CLIENT;
	unities.idUser = CLIENT;

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "CreateProductUnityBulk",
			"variables": {
				"value": unities
			}
		}
		);

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("Authorization", "Bearer " + JWT_TOKEN);

		xhr.send(data);
	});
}

/* ANALYTICS */
export function createAnalytic(type, analytic) {
	const mutation = `mutation CreateAnalytic($value: AnalyticInput!) {
    createAnalytic(value: $value) {
      id
      object
      type
    }
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": mutation,
			"operationName": "CreateAnalytic",
			"variables": {
				"value": {
					"idClient": CLIENT,
					"idUser": CLIENT,
					"type": type,
					"object": analytic
				}
			}
		}
		);

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");

		xhr.send(data);
	});
}

export function getInteractionsByProductWithType(type, idProduct){
  const query = `query Analytics($filter: AnalyticQueryFilter!) {
    analytics(filter: $filter) {
      id
      idClient
      idUser
      type
      object
    }
  }`

	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();

		var data = JSON.stringify({
			"query": query,
			"operationName": "Analytics",
			"variables": {
				"filter": {
          "type": type,
          "idClient": CLIENT,
          "idProduct": idProduct
        }
			}
		});

		xhr.onload = () => resolve(
			{
				response: JSON.parse(xhr.response),
				status: xhr.status
			}
		);
		xhr.onerror = () => reject(xhr.statusText);
		xhr.open("POST", GRAPHQLURL);
		xhr.setRequestHeader("Content-Type", "application/json");

		xhr.send(data);
	});
}